import { Route, Switch, Redirect } from 'react-router-dom';
import { Sidebar, SplitLayout, SplitContainer, ZeroDetail } from '@rr/ui';
import * as ROUTES from 'config/routes';

import { StudentList } from './StudentList';
import { StudentDetail } from './StudentDetail';

const Students = () => {
  const lastStudent = localStorage.getItem('lastStudent');
  return (
    <SplitLayout>
      <Sidebar.Container>
        <StudentList />
      </Sidebar.Container>
      <SplitContainer>
        <Switch>
          <Route path={ROUTES.STUDENTS_UPDATE} component={StudentDetail} />
          {lastStudent && <Redirect to={`/students/${lastStudent}`} />}
          <Route>
            <ZeroDetail title="No student selected">
              Please select one
            </ZeroDetail>
          </Route>
        </Switch>
      </SplitContainer>
    </SplitLayout>
  );
};

export { Students };
