import { css } from '@emotion/react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { MiniTable, SplitContainer, SectionTitle } from '@rr/ui';

import * as ROUTES from 'config/routes';
import { useMantineTheme } from '@mantine/core';

import { UpcomingLessons } from './UpcomingLessons';
import { AllLessons } from './AllLessons';

export const Lessons = () => {
  const theme = useMantineTheme();
  const styles = css`
    padding: 24px;
    height: calc(100vh - 64px);

    .container {
      background: white;
      border-radius: 4px;
      box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.3);

      .header {
        padding: 24px;
        border-bottom: 1px solid ${theme.colors.gray[1]};
        display: flex;
        align-items: center;
        justify-content: space-between;

        .l {
          display: flex;
          gap: 16px;
          align-items: center;

          .name {
            font-size: 1.4rem;
            font-weight: 600;
          }

          .badges {
            display: flex;
            gap: 6px;
          }
        }

        .r {
          display: flex;
          gap: 8px;
        }
      }

      .general,
      .lessons,
      .locations,
      .plans,
      .teachers {
        padding: 24px;

        a {
          display: block;
        }
      }

      .split {
      }
    }
  `;
  return (
    <div css={styles}>
      <div className="container">
        <div className="header">
          <div className="l">
            <div className="name t1">Lessons</div>
          </div>

          <div className="r"></div>
        </div>
        <div className="split">
          <div className="lessons schedule">
            <SectionTitle title="Upcoming Lessons" />
            <UpcomingLessons />
          </div>
          <div className="lessons list">
            <SectionTitle title="All Lessons" />
            <AllLessons />
          </div>
        </div>
      </div>
    </div>
  );
};
