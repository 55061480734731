import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { AppContainer } from '@rr/ui';

import * as ROUTES from 'config/routes';
import { Layout } from 'components';
import { Auth } from '@rr/auth-context';
import { Login, AuthLink, AccountCreate, Students, Lessons } from 'routes';

export function App() {
  return (
    <Auth>
      <AppContainer>
        <Router>
          <Switch>
            <Route path={ROUTES.LOGIN} component={Login} />
            <Route path={ROUTES.VERIFY_LINK} component={AuthLink} />
            <Route path={ROUTES.ACCOUNT_CREATE} component={AccountCreate} />
            <Route>
              <Layout>
                <Switch>
                  {/* Remove Dashboard component */}
                  <Redirect exact from="/" to={ROUTES.LESSONS} />
                  <Route path={ROUTES.LESSONS} component={Lessons} />
                  <Route path={ROUTES.STUDENTS} component={Students} />
                </Switch>
              </Layout>
            </Route>
          </Switch>
        </Router>
      </AppContainer>
    </Auth>
  );
}

export default App;
