import { gql } from '@apollo/client';

export const STUDENT_LESSON_CREATE_MUTATION = gql`
  mutation StudentLessonCreateScopedTeacher(
    $teacherId: String!
    $studentId: uuid!
    $instrumentId: uuid!
    $locationId: uuid!
    $planId: uuid!
    $startTime: timestamptz!
    $endTime: timestamptz!
  ) {
    insert_lessons_one(
      object: {
        instrument_id: $instrumentId
        location_id: $locationId
        plan_id: $planId
        student_id: $studentId
        teacher_id: $teacherId
        start_time: $startTime
        end_time: $endTime
      }
    ) {
      id
    }
  }
`;
