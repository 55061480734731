import { gql } from '@apollo/client';

export const GET_STUDENT_LESSONS_QUERY = gql`
  query GetStudentLessons($id: uuid!, $limit: Int!, $offset: Int!) {
    students_by_pk(id: $id) {
      id
      lessons(
        limit: $limit
        offset: $offset
        order_by: { start_time: asc }
        where: { start_time: { _is_null: false } }
      ) {
        start_time
        status
        end_time
        teacher_id
        user {
          id
          gdpr_name
        }
        location {
          name
        }
        instrument {
          prettyName
        }
        plan {
          id
          prettyName
        }
      }
      lessons_aggregate(where: { start_time: { _is_null: false } }) {
        aggregate {
          count
        }
      }
    }
  }
`;
