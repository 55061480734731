import { gql } from '@apollo/client';

export const GET_STUDENT_QUERY = gql`
  query GetStudent($id: uuid!) {
    students_by_pk(id: $id) {
      id
      first_name
      last_name
      class_code
      class_name
      notes
      student_users {
        user {
          id
          gdpr_name
          email
          phone
        }
      }
      student_instruments {
        instrument {
          prettyName
          id
        }
      }
    }
    instruments {
      prettyName
      id
    }
  }
`;
