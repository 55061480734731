import { useState, Fragment } from 'react';
import { css } from '@emotion/react';
import { Redirect } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Button,
  Space,
  Alert,
  Card,
  Image,
  useMantineTheme,
  Title,
  Divider,
} from '@mantine/core';

import { Form } from '@rr/ui';
import { useAuth } from '@rr/auth-context';

import * as ROUTES from 'config/routes';

const VALIDATION_SCHEMA = Yup.object({
  email: Yup.string().email('Please enter a valid email').required('Required.'),
});

const Login = () => {
  const theme = useMantineTheme();
  const styles = css`
    background: ${theme.colors.indigo[0]};
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  `;

  const { login, user } = useAuth();
  const [awaitingLink, setAwaitingLink] = useState(false);

  return user ? (
    <Redirect to={ROUTES.LESSONS} />
  ) : (
    <div css={styles}>
      <div style={{ width: 340 }}>
        <Card shadow="md" padding="lg">
          <Card.Section>
            <Image src="https://unsplash.it/300/120" />
          </Card.Section>
          <Space h="md" />
          <Title order={3}>Login to LessonTime</Title>
          <Divider />
          <Space h="md" />
          <Form.Context
            validationSchema={VALIDATION_SCHEMA}
            initialValues={{ email: '', password: '' }}
            onSubmit={(values, { setSubmitting }) => {
              login({ ...values })
                // @ts-expect-error Stupid
                .then(() => {
                  window.localStorage.setItem('emailForSignIn', values.email);
                  setAwaitingLink(true);
                  setSubmitting(false);
                })
                .catch(console.error);
            }}
          >
            {({ isSubmitting, isValid, submitForm }) => {
              const SUBMIT_STRING = !isSubmitting ? 'Log in' : 'Waiting...';
              return (
                <Form.Wrapper className="wrapper">
                  {awaitingLink ? (
                    <Alert color="green" title="Thanks!">
                      Please check your email for a sign-in link.
                    </Alert>
                  ) : (
                    <Fragment>
                      <Form.FieldNew
                        type="email"
                        name="email"
                        label="Email address"
                        placeholder="Email address"
                        fullWidth
                        disabled={isSubmitting}
                      />
                      <Space h="md" />
                      <Button
                        component="button"
                        onClick={submitForm}
                        fullWidth
                        disabled={isSubmitting || !isValid}
                        loading={isSubmitting}
                      >
                        {SUBMIT_STRING}
                      </Button>
                    </Fragment>
                  )}
                </Form.Wrapper>
              );
            }}
          </Form.Context>
        </Card>
      </div>
    </div>
  );
};

export { Login };
