import { Calendar } from '@rr/ui';
import { useAuth } from '@rr/auth-context';

import { useGetUpcomingScopedLessonsQuery } from 'types/graphql';

export const UpcomingLessons = () => {
  const { user } = useAuth();
  const { data, error, loading } = useGetUpcomingScopedLessonsQuery({
    variables: {
      id: (user && user.uid) || '',
    },
  });

  if (error) {
    console.error(error);
    return <div>Error</div>;
  }

  if (loading) {
    return <div></div>;
  }

  const lessons = data?.lessons || [];

  const events = lessons.map((lesson) => ({
    id: lesson.id,
    title: `${lesson.student.gdpr_name} / ${lesson.instrument?.prettyName} / ${lesson.location?.name}`,
    start: lesson.start_time,
    end: lesson.end_time,
    resourceId: lesson.user.id,
  }));

  return (
    <div>
      <Calendar
        initialView="dayGridWeek"
        events={events}
        height="600px"
        nowIndicator
      />
    </div>
  );
};
