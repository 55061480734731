import { useState } from 'react';
import { useGetAllScopedLessonsQuery } from 'types/graphql';
import { useAuth } from '@rr/auth-context';
import { humanDate } from '@rr/utils';
import { MiniTable } from '@rr/ui';

import { Badge } from '@mantine/core';

export const AllLessons = () => {
  const [offset, setOffset] = useState(0);
  const LIMIT = 5;

  const updateOffset = (number: number) => setOffset(number);

  const { user } = useAuth();
  const { data, error, loading } = useGetAllScopedLessonsQuery({
    variables: {
      id: (user && user.uid) || '',
    },
  });

  if (error) {
    console.error(error);
    return <div>Error</div>;
  }

  if (loading) {
    return <div></div>;
  }

  const LESSON_COLUMNS = [
    {
      Header: 'Date',
      accessor: 'date',
    },
    {
      Header: 'Time',
      accessor: 'start_time',
      // @ts-expect-error dumb
      Cell: ({ value, cell }) =>
        value ? (
          <>
            {value} - {cell.row.original.end_time}
          </>
        ) : (
          <Badge color="red" variant="filled">
            Time not booked
          </Badge>
        ),
    },
    {
      Header: 'Status',
      accessor: 'status',
      // @ts-expect-error dumb
      Cell: ({ value, cell }) => {
        console.log(cell);
        return <div>{cell.row.original.status}</div>;
      },
    },
    {
      Header: 'Student',
      accessor: 'student',
    },
    {
      Header: 'Instrument',
      accessor: 'instrument',
      // @ts-expect-error dumb
      Cell: ({ value, cell }) =>
        value ? (
          <div>{value}</div>
        ) : (
          <Badge color="red" variant="filled">
            Instrument missing
          </Badge>
        ),
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
  ];

  const lessonData =
    data?.lessons.map((lesson) => ({
      date: humanDate(lesson.start_time),
      start_time: humanDate(lesson.start_time, {
        minute: '2-digit',
        hour: '2-digit',
        hour12: true,
      }),
      end_time: humanDate(lesson.end_time, {
        minute: '2-digit',
        hour: '2-digit',
        hour12: true,
      }),
      student: lesson.student.gdpr_name,
      location: lesson.location?.name,
      instrument: lesson.instrument?.prettyName || '',
      status: lesson.status,
    })) || [];

  return lessonData.length !== 0 ? (
    <MiniTable.Wrapper
      columns={LESSON_COLUMNS}
      data={lessonData}
      offset={offset}
      count={data?.lessons_aggregate.aggregate?.count}
      limit={LIMIT}
      updateOffset={updateOffset}
    >
      <MiniTable.Header />
      <MiniTable.Body />
      <MiniTable.Pagination />
    </MiniTable.Wrapper>
  ) : (
    <MiniTable.Zero>No lessons</MiniTable.Zero>
  );
};
