import { css } from '@emotion/react';
import { Avatar, Button, useMantineTheme } from '@mantine/core';
import { Header, Logo, Navigation, NavigationLink } from '@rr/ui';
import { useAuth } from '@rr/auth-context';

// TODO: Replace with Route constants
const NAVIGATION: NavigationLink[] = [
  {
    to: '/lessons',
    label: 'Lessons',
  },
  {
    to: '/students',
    label: 'Students',
  },
];

const Layout: React.FC = ({ children }) => {
  const theme = useMantineTheme();
  const styles = css`
    background: ${theme.colors.blue[7]};
    height: 100vh;

    .content {
      flex: 1 0 auto;
      height: calc(100vh - 64px);
    }

    .Avatar {
      margin-left: auto;
      margin-right: 8px;
    }

    .SplitLayout {
      display: flex;
      height: 100%;
      padding: 0 24px;
      gap: 24px;
    }
  `;

  const { logout, user } = useAuth();

  return (
    <div css={styles}>
      <Header>
        <Logo />
        <Navigation items={NAVIGATION} />
        <Avatar className="Avatar" radius="xl" color="blue">
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          {user?.email?.charAt(0).toUpperCase() || 'NO'}
        </Avatar>
        <Button variant="subtle" className="Logout" onClick={logout}>
          Log out
        </Button>
      </Header>
      <div className="content">{children}</div>
    </div>
  );
};

export { Layout };
