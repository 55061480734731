import { Fragment, useState, useEffect } from 'react';
import { css } from '@emotion/react';
import { useParams } from 'react-router-dom';
import { Avatar, Button, Badge, useMantineTheme } from '@mantine/core';
import { SectionTitle, InfoItem, Grid } from '@rr/ui';
import { getRandomThemeColor } from '@rr/utils';

import { useQuery } from '@apollo/client';
import { GET_STUDENT_QUERY } from './GetStudent.query.gql';
import { GetStudentQuery } from 'types/graphql';

import { StudentLessons } from './StudentLessons';
import { StudentLocations } from './StudentLocations';
import { StudentLessonCreate } from './StudentLessonCreate';

export const StudentDetail = () => {
  const [createLessonModalOpen, setCreateLessonModalOpen] = useState(false);
  const theme = useMantineTheme();
  const { studentId }: { studentId: string } = useParams();

  useEffect(() => {
    localStorage.setItem('lastStudent', studentId);
  }, [studentId]);

  const { loading, error, data } = useQuery<GetStudentQuery>(
    GET_STUDENT_QUERY,
    {
      variables: {
        id: studentId,
      },
    }
  );

  if (loading) return <div></div>;
  if (error) {
    console.error(error);
    return <div>Error</div>;
  }

  const styles = css`
    background: white;
    border-radius: 4px;
    box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.3);

    .header {
      padding: 24px;
      border-bottom: 1px solid ${theme.colors.gray[1]};
      display: flex;
      align-items: center;
      justify-content: space-between;

      .l {
        display: flex;
        gap: 16px;
        align-items: center;

        .name {
          font-size: 1.4rem;
          font-weight: 600;
        }

        .badges {
          display: flex;
          gap: 6px;
        }
      }

      .r {
        display: flex;
        gap: 8px;
      }
    }

    .general,
    .lessons,
    .locations,
    .plans,
    .teachers {
      padding: 24px;

      a {
        display: block;
      }
    }
  `;

  return (
    <Fragment>
      <div css={styles}>
        <div className="header">
          <div className="l">
            <Avatar color={getRandomThemeColor()} radius="xl">
              {data?.students_by_pk?.first_name.charAt(0)}
              {data?.students_by_pk?.last_name.charAt(0)}
            </Avatar>
            <div className="name t1">
              {data?.students_by_pk?.first_name}{' '}
              {data?.students_by_pk?.last_name}
            </div>
            <div className="badges">
              {data?.students_by_pk?.student_instruments.map(
                ({ instrument }) => (
                  <Badge
                    key={instrument.prettyName}
                    size="md"
                    color={getRandomThemeColor()}
                  >
                    {instrument.prettyName}
                  </Badge>
                )
              )}
            </div>
          </div>

          <div className="r"></div>
        </div>
        <div className="general">
          <SectionTitle title="Basic information" />
          <Grid columns={3}>
            <InfoItem title="Guardian name">
              {data?.students_by_pk?.student_users[0].user.gdpr_name}
            </InfoItem>
            <InfoItem title="Guardian phone">
              {data?.students_by_pk?.student_users[0].user.phone}
            </InfoItem>
            <InfoItem title="Guardian email">
              {data?.students_by_pk?.student_users[0].user.email}
            </InfoItem>
          </Grid>
        </div>
        <div className="lessons">
          <SectionTitle title="Lessons">
            <Button
              onClick={() => setCreateLessonModalOpen(true)}
              variant="outline"
              color="blue"
              size="sm"
            >
              + Create Lesson
            </Button>
          </SectionTitle>
          <StudentLessons />
        </div>
        <div className="locations">
          <SectionTitle title="Locations" />
          <StudentLocations />
        </div>
      </div>
      <StudentLessonCreate
        opened={createLessonModalOpen}
        onClose={setCreateLessonModalOpen}
      />
    </Fragment>
  );
};
