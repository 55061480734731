import { css } from '@emotion/react';
import { useHistory, Redirect } from 'react-router-dom';

import { Button, Form } from '@rr/ui';
import { useAuth } from '@rr/auth-context';

import * as ROUTES from 'config/routes';

const AccountCreate = () => {
  const styles = css`
    background: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .wrapper {
      width: 380px;

      .Field {
        margin-bottom: 16px;
      }
    }
  `;

  const { createAccount, user } = useAuth();
  const history = useHistory();

  return user ? (
    <Redirect to={ROUTES.LESSONS} />
  ) : (
    <div css={styles}>
      <Form.Context
        initialValues={{ email: '', password: '', firstName: '', lastName: '' }}
        onSubmit={(values, { setSubmitting }) => {
          // @ts-expect-error Stupid
          createAccount({ ...values }).then((response) => {
            history.replace(ROUTES.LESSONS);
          });
        }}
      >
        {({ isSubmitting }) => (
          <Form.Wrapper className="wrapper">
            <Form.Field
              type="email"
              name="email"
              placeholder="Email address"
              fullWidth
              disabled={isSubmitting}
            />
            <Form.Field
              type="password"
              name="password"
              placeholder="Password"
              fullWidth
              disabled={isSubmitting}
            />
            <Form.Field
              type="text"
              name="firstName"
              placeholder="First name"
              fullWidth
              disabled={isSubmitting}
            />
            <Form.Field
              type="text"
              name="lastName"
              placeholder="Last name"
              fullWidth
              disabled={isSubmitting}
            />
            <Form.Button fullWidth disabled={isSubmitting}>
              {!isSubmitting ? 'Create account' : 'Waiting...'}
            </Form.Button>
          </Form.Wrapper>
        )}
      </Form.Context>
    </div>
  );
};

export { AccountCreate };
