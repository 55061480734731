import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Modal, Button, Space } from '@mantine/core';
import * as Yup from 'yup';

import { useAuth } from '@rr/auth-context';
import { Form } from '@rr/ui';
import { combineDateTime } from '@rr/utils';
import { STUDENT_LESSON_CREATE_VALUES } from './StudentLessonCreate.query.gql';
import { STUDENT_LESSON_CREATE_MUTATION } from './StudentLessonCreate.mutation.gql';
import { StudentLessonCreateValuesQuery } from 'types/graphql';

// TODO Create a shared type from this badass interface
interface StudentLessonCreateProps {
  opened: boolean;
  onClose: (boolean: boolean) => void;
}

export const StudentLessonCreate = ({
  opened,
  onClose,
}: StudentLessonCreateProps) => {
  const { studentId }: { studentId: string } = useParams();
  const { user } = useAuth();

  const { data, loading, error } = useQuery<StudentLessonCreateValuesQuery>(
    STUDENT_LESSON_CREATE_VALUES,
    {
      variables: {
        id: studentId,
      },
    }
  );

  const [createLesson] = useMutation(STUDENT_LESSON_CREATE_MUTATION, {
    refetchQueries: ['GetStudentLessons'],
  });

  const VALIDATION_SCHEMA = Yup.object({
    instrumentId: Yup.string().required('Required'),
    locationId: Yup.string().required('Required'),
    time: Yup.date().required('Required'),
    date: Yup.date().required('Required'),
  });

  const INITIAL_VALUES = {
    instrumentId: '',
    locationId: '',
    time: new Date(),
    date: new Date(),
  };

  if (error) {
    console.error(error);
    return <div>Error</div>;
  }

  if (loading) {
    return <div></div>;
  }

  return (
    <Modal
      opened={opened}
      onClose={() => onClose(false)}
      title="Create a Lesson"
    >
      <Form.Context
        validationSchema={VALIDATION_SCHEMA}
        initialValues={INITIAL_VALUES}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            const variables = {
              teacherId: user && user.uid,
              studentId,
              instrumentId: values.instrumentId,
              locationId: values.locationId,
              time: combineDateTime({
                time: values.time,
                date: values.date,
              }),
              planId: data?.students_by_pk?.student_plans[0].plan.id,
            };

            createLesson({
              variables,
            }).then(() => {
              onClose(false);
            });
          }, 1000);
        }}
      >
        {({ isSubmitting, isValid, submitForm }) => (
          <Form.Wrapper>
            <Form.Select
              label="Instrument"
              data={data?.students_by_pk?.student_instruments.map(
                ({ instrument }) => ({
                  value: instrument.id,
                  label: instrument.prettyName,
                })
              )}
              name="instrumentId"
            />
            <Space h="md" />
            <Form.Select
              label="Location"
              data={data?.students_by_pk?.student_locations.map(
                ({ location }) => ({
                  value: location.id,
                  label: location.name,
                })
              )}
              name="locationId"
            />
            <Space h="md" />
            <Form.DatePicker label="Date" name="date" />
            <Space h="md" />
            <Form.TimeInput label="Time" name="time" />
            <Space h="md" />
            <Button
              className="Button"
              component="button"
              onClick={submitForm}
              fullWidth
              disabled={isSubmitting || !isValid}
              loading={isSubmitting}
            >
              {!isSubmitting ? 'Create lesson' : 'Waiting...'}
            </Button>
          </Form.Wrapper>
        )}
      </Form.Context>
    </Modal>
  );
};
