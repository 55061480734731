// VERB ADDITIONS
const VERB_CREATE = 'create';

// AUTHORIZATION ROUTES
export const LOGIN = '/login';
export const ACCOUNT_CREATE = `/${VERB_CREATE}-account`;
export const VERIFY_LINK = '/verify';

// STUDENTS
export const STUDENTS = '/students';
export const STUDENTS_CREATE = `${STUDENTS}/${VERB_CREATE}`;
export const STUDENTS_UPDATE = `${STUDENTS}/:studentId`;

// LESSONS
export const LESSONS = `/lessons`;
