import { gql } from '@apollo/client';

export const STUDENT_LESSON_CREATE_VALUES = gql`
  query StudentLessonCreateValues($id: uuid!) {
    students_by_pk(id: $id) {
      id
      student_instruments {
        instrument {
          id
          prettyName
        }
      }
      student_plans {
        plan {
          id
        }
      }
      student_locations {
        location {
          id
          name
        }
      }
    }
  }
`;
